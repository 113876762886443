import Vue from 'vue'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
// https://floating-vue.starpad.dev/guide/installation.html#vue-2
import { VTooltip, Dropdown } from 'floating-vue'
import 'floating-vue/dist/style.css'
import '@/assets/css/vendors/floating-vue.css'

export default defineNuxtPlugin(() => {
  Vue.directive('tooltip', VTooltip)
  Vue.component('VDropdown', Dropdown)
})
