export default {
  customer: {
    contactCentric: false
  },
  // product: {
  //   details: {
  //     commonConnectionTypes: ['Interesting', 'Replacement'],
  //     VariantConnectionTypes: ['Size', 'Color', 'Variant'],
  //   },
  // },
  // checkout: {
  //   payment: {
  //     provider: 'Ogone'
  //   }
  // },
  axios: {
    urlsToCache: [
      // '/api/vm/u',
      // '/api/sp/config',
      // '/api/aspos/common/config',
      // '/api/aspos/webnodes/main/root'
    ]
  }
}
